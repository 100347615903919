import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import { ProductPage } from '@talentinc/gatsby-theme-ecom/types/productPageV2'
import BaseLandingPageV2Template from '@talentinc/gatsby-theme-ecom/components/Templates/LandingPageV2'
import ProductPageSEO from '@talentinc/gatsby-theme-ecom/components/SEO/ProductPageSEO'
import { PageProductProvider } from '@talentinc/gatsby-theme-ecom/hooks/usePageProduct'

type Props = PageProps<{ productPage: ProductPage } & PageDataContextType>

const ProductPageTemplate = (props: Props) => {
  const { productPage, ...pageData } = props.data

  return (
    <Layout pageTitle={productPage.title} pageData={pageData}>
      <PageProductProvider productPackage={productPage.product.productPackage}>
        <SEO landingPage={productPage} />
        <ProductPageSEO landingPage={productPage} />
        <HeaderV2 />
        <BaseLandingPageV2Template landingPage={productPage} />
        <Footer />
      </PageProductProvider>
    </Layout>
  )
}

export const query = graphql`
  query ProductPageBySlugAndBrand($slug: String!, $brandName: String!) {
    productPage: contentfulProductPage(
      slug: { eq: $slug }
      node_locale: { eq: "fr" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...ProductPage
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: {
        elemMatch: { name: { eq: $brandName }, node_locale: { eq: "fr" } }
      }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(
      brand: { name: { eq: $brandName }, node_locale: { eq: "fr" } }
    ) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "fr" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ProductPageTemplate
